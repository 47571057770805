import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: '', loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule), data: { breadcrumb: 'Homepage' } }, 
  { path: 'home-v2', loadChildren: () => import('./components/pages/hometwo/hometwo.module').then(m => m.HometwoModule), data: { breadcrumb: 'Homepage' } }, 
  { path: 'about', loadChildren: () => import('./components/pages/about/about.module').then(m => m.AboutModule), data: { breadcrumb: 'About Us' } },
  { path: 'page/:id', loadChildren: () => import('./components/pages/page/page.module').then(m => m.PageModule), data: { breadcrumb: 'Page' } }, 
  { path: 'cart', loadChildren: () => import('./components/pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } }, 
  { path: 'blog-sidebar', loadChildren: () => import('./components/pages/blogleft/blogleft.module').then(m => m.BlogleftModule), data: { breadcrumb: 'Blog Left' } }, 
  { path: 'blog-single/:id', loadChildren: () => import('./components/pages/blogdetail/blogdetail.module').then(m => m.BlogdetailModule), data: { breadcrumb: 'Blog Details' } }, 
  { path: 'coming-soon', loadChildren: () => import('./components/pages/comingsoon/comingsoon.module').then(m => m.ComingsoonModule), data: { breadcrumb: 'Coming Soon' } }, 
  { path: 'contact', loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'Contact Us' } }, 
  { path: 'error', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'Error 404' } }, 
  { path: 'finance', loadChildren: () => import('./components/pages/finance/finance.module').then(m => m.FinanceModule), data: { breadcrumb: 'Finance' }  },
  { path: 'place-order', loadChildren: () => import('./components/pages/placeorder/placeorder.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Place Order' }  },
  { path: 'profile', loadChildren: () => import('./components/pages/profile/profile.module').then(m => m.ProfileModule), data: { breadcrumb: 'Profile' }  },
  { path: 'products', loadChildren: () => import('./components/pages/products/products.module').then(m => m.productsModule), data: { breadcrumb: 'Products' }  },
  { path: 'categories', loadChildren: () => import('./components/pages/categories/categories.module').then(m => m.CategoriesModule), data: { breadcrumb: 'Categories' }  }, 
  { path: 'orders', loadChildren: () => import('./components/pages/orders/orders.module').then(m => m.ordersModule), data: { breadcrumb: 'Orders' }  }, 
  { path: 'settings', loadChildren: () => import('./components/pages/settings/settings.module').then(m => m.settingsModule), data: { breadcrumb: 'Settings' }  }, 
  { path: 'users', loadChildren: () => import('./components/pages/users/users.module').then(m => m.usersModule), data: { breadcrumb: 'Users' }  }, 
  { path: 'add-product', loadChildren: () => import('./components/pages/add-product/add-product.module').then(m => m.AddProductModule), data: { breadcrumb: 'Add Product' }  },
  { path: 'add-category', loadChildren: () => import('./components/pages/add-category/add-category.module').then(m => m.AddCategoryModule), data: { breadcrumb: 'Add Category' }  },
  { path: 'edit-product/:id', loadChildren: () => import('./components/pages/edit-product/edit-product.module').then(m => m.EditProductModule), data: { breadcrumb: 'Edit Product' }  },
  { path: 'edit-category/:id', loadChildren: () => import('./components/pages/edit-category/edit-category.module').then(m => m.EditCategoryModule), data: { breadcrumb: 'Edit Category' }  }, 
  { path: 'add-setting', loadChildren: () => import('./components/pages/add-setting/add-setting.module').then(m => m.AddProductModule), data: { breadcrumb: 'Add Setting' }  }, 
  { path: 'editSettings/:id', loadChildren: () => import('./components/pages/editsettings/editsettings.module').then(m => m.settingsModule), data: { breadcrumb: 'Edit Settings' }  }, 
  { path: 'edit-user/:id', loadChildren: () => import('./components/pages/edit-user/edit-user.module').then(m => m.EditProductModule), data: { breadcrumb: 'Edit User' }  }, 
  { path: 'signup', loadChildren: () => import('./components/pages/signup/signup.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Sign Up!' }  }, 
  { path: 'login', loadChildren: () => import('./components/pages/login/login.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Log In!' }  }, 
  { path: 'forgot-password', loadChildren: () => import('./components/pages/forgot-password/forgot-password.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Forgot Password!' }  }, 
  { path: 'admin_page', loadChildren: () => import('./components/pages/admin_interface/admin.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Admin' }  }, 
  { path: 'loan-calculator', loadChildren: () => import('./components/pages/loancalculator/loancalculator.module').then(m => m.LoancalculatorModule), data: { breadcrumb: 'Loan Calculator' } }, 
  { path: 'loan-step', loadChildren: () => import('./components/pages/loanstep/loanstep.module').then(m => m.LoanstepModule), data: { breadcrumb: 'Loan Steps' } }, 
  { path: 'service', loadChildren: () => import('./components/pages/service/service.module').then(m => m.ServiceModule), data: { breadcrumb: 'Service' } }, 
  { path: 'service-detail/:id', loadChildren: () => import('./components/pages/servicedetail/servicedetail.module').then(m => m.ServicedetailModule), data: { breadcrumb: 'Service Details' } }, 
  { path: 'reset-password/:guid', loadChildren: () => import('./components/pages/reset-password/reset-password.module').then(m => m.PlaceorderModule), data: { breadcrumb: 'Reset Password' } }, 
  { path: 'privacy-policy', loadChildren: () => import('./components/pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule), data: { breadcrumb: 'Privacy Policy' } },
  { path: 'terms-and-conditions', loadChildren: () => import('./components/pages/terms-and-conditions/terms-and-conditions.module').then(m => m.TermsAndConditionsModule), data: { breadcrumb: 'Terms And Conditions' } },
  { path: 'team', loadChildren: () => import('./components/pages/team/team.module').then(m => m.TeamModule), data: { breadcrumb: 'Team' } }, 
  { path: 'loan-dashboard', loadChildren: () => import('./components/pages/userdashboard/userdashboard.module').then(m => m.UserdashboardModule), data: { breadcrumb: 'User Dashboard' } },
  { path: '**', loadChildren: () => import('./components/pages/error/error.module').then(m => m.ErrorModule), data: { breadcrumb: 'Error 404' } },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
